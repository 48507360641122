import React from "react";
import Header3 from "../header/Header3";
import IntroTerm from "../main/IntroTerm";

const TermPlan = () => {
  return (
    <>
      <Header3 />
      <IntroTerm />
    </>
  );
};

export default TermPlan;
