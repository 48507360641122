import React from "react";
import Header2 from "../header/Header2";
import Intro2 from "../main/Intro2";

const About = () => {
  return (
    <>
      <Header2 />
      <Intro2 />
    </>
  );
};

export default About;
