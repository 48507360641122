import React from "react";
import Header3 from "../header/Header3";
import BlogList from "../main/BlogList";

const Blog = () => {
  return (
    <>
      <Header3 />
      <BlogList />
    </>
  );
};

export default Blog;
