import React from "react";
import Header3 from "../header/Header3";
import Introhni from "../main/Introhni";

const Retire = () => {
  return (
    <>
      <Header3 />
      <Introhni />
    </>
  );
};

export default Retire;
