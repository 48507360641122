import React from "react";
import Header3 from "../header/Header3";
import IntroHI from "../main/IntroHI";

const HealthInsure = () => {
  return (
    <>
      <Header3 />
      <IntroHI />
    </>
  );
};

export default HealthInsure;
