import React from "react";
import Header3 from "../header/Header3";
import IntroGI from "../main/IntroGI";

const GeneralInsurance = () => {
  return (
    <>
      <Header3 />
      <IntroGI />
    </>
  );
};

export default GeneralInsurance;
