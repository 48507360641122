import React from "react";
import Header3 from "../header/Header3";
import IntroKeyman from "../main/IntroKeyman";

const Keyman = () => {
  return (
    <>
      <Header3 />
      <IntroKeyman />
    </>
  );
};

export default Keyman;
